import { IResourceComponentsProps, useApiUrl, useNotification } from "@pankod/refine-core";
import {
  List,
  Button,
  Card,
  Icons,
} from "@pankod/refine-antd";
import { BFF_KEY } from "environment";

export const ControlPanel: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const toast = useNotification();

  async function handlePlanTable() {
    await fetch(`${apiUrl}/v1/consumers/plans`, {
      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY,
      },
    }).then(() => {
      toast.open?.({
        message: 'Planos Reprocessados com sucesso.',
        type: 'success',
      })
    }).catch(() => {
      toast.open?.({
        message: 'Ocorreu um erro ao tentar reprocessar os planos, tente novamente mais tarde.',
        type: 'error',
      })
    })
  }

  return (
    <List>
      <Card title="Ações">
        <div style={{ display: 'flex' }}>
          <Button
            size="large"
            icon={<Icons.UpCircleOutlined />}
            onClick={handlePlanTable}
          >
            Reprocessar Planos
          </Button>
        </div>
      </Card>
    </List>
  );
};
