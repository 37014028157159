import {
  Form,
  FormProps,
  Input,
  Select,
} from "@pankod/refine-antd";
import { useEffect } from "react";

type PlansFamilyParamsProps = {
  formProps: FormProps;
  plans_family_id: number | undefined;
}

export const PlansFamilyParamsForm: React.FC<PlansFamilyParamsProps> = ({ formProps, plans_family_id }) => {

  useEffect(() => {
    formProps.form?.resetFields();
  });

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        plan_family_id: plans_family_id
      }}
    >
      <Form.Item name="plan_family_id" hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Chave"
        name="key"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Variação"
        name="variation"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tipo"
        name="type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={
            [
              { value: 'texto', label: <span>Texto</span> },
              { value: 'url', label: <span>URL</span> },
            ]}
        />
      </Form.Item>

      <Form.Item
        label="Valor"
        name="value"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );

}