import {
  Form,
  Input,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";
import { IBranchOfficesSearch } from "interfaces";

export const PresencePointsForm: React.FC<MyFormProps> = ({ formProps }) => {

  const { selectProps } = useSelect<IBranchOfficesSearch>({
    resource: "v1/branch-office/distinct-ids",
    optionLabel: "branch_id",
    optionValue: "branch_id",
    hasPagination: false
  });

  return (
    <Form {...formProps} layout="vertical">

      <Form.Item
        label="Nome"
        name="facility_name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Filial"
        name="branch_id"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select {...selectProps} />
      </Form.Item>

      <Form.Item
        label="Tipo"
        name="type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={
            [
              { value: 'clinica', label: <span>Clinica</span> },
              { value: 'hospital', label: <span>Hospital</span> },
              { value: 'laboratorio-imagem', label: <span>Laboratório e Imagem</span> },
              { value: 'pronto-atendimento', label: <span>Pronto Atendimento</span> },
              { value: 'outros', label: <span>Outros</span> },
            ]}
        />
      </Form.Item>

      <Form.Item
        label="Endereço"
        name="address"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Latitude (Utilize apenas ponto '.' como delimitador de casas decimais)"
        name="latitude"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Longitude (Utilize apenas ponto '.' como delimitador de casas decimais)"
        name="longitude"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="URL da Imagem da Fachada"
        name="image"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );

}