import { CrudFilters, CrudOperators, DataProvider } from "@pankod/refine-core";
import restDataProvider from "@pankod/refine-simple-rest";
import { stringify } from "query-string";
import { AxiosInstance } from "axios";

import { ADMIN_BFF_URL, BFF_KEY } from "./environment";

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "eq":
            return "";
        case "in":
            return "";
        case "ne":
            return "";
        default:
            throw new Error(`Operator ${operator} is not supported`);
    }
};

const config = {
    headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY,
    }
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    if (filters) {
        filters.forEach((filter: any) => {
            if (filter.operator !== "or") {
                const { field, operator, value } = filter;

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};

export const dataProvider = (axios: AxiosInstance): DataProvider => {
    return {
        ...restDataProvider(ADMIN_BFF_URL, axios),
        getList: async ({ resource, pagination, filters, hasPagination, metaData }) => {
            const url_resource = `${ADMIN_BFF_URL}/${resource}/`;

            // pagination
            const current = pagination?.current || 1;
            const pageSize = pagination?.pageSize || 100;
            const sort = "id";
            const order = "asc";

            const queryFilters = generateFilter(filters);

            const query = {
                page: current,
                size: pageSize,
                //sort: sort,
                //order: order
            };

            let url = new URL(url_resource);

            const queryParams = [...Object.entries(metaData?.query ?? {}), ...Object.entries(query), ...Object.entries(queryFilters)]
            for (const [key, value] of queryParams) {
                url.searchParams.set(key, String(value))
            }

            const { data } = await axios.get(
                url.toString(),
                config,
            );

            if (data.items == undefined && !hasPagination) {
                return {
                    data: data,
                    total: data.length
                };
            }

            return {
                data: data.items,
                total: data.total
            };
        },
        getOne: async ({ resource, id, metaData }) => {
            let url = metaData?.getComments
                ? `${ADMIN_BFF_URL}/${resource}/${id}/comments`
                : `${ADMIN_BFF_URL}/${resource}/${id}`;

                if (metaData?.query) {
                    const newUrl = new URL(url);
                    for (const [key, value] of Object.entries(metaData?.query)) {
                        newUrl.searchParams.set(key, value as string)
                    }
                    url = newUrl.toString();
                }

            const { data } = await axios.get(url, config);

            return {
                //data: data[metaData?.resource || resource],
                data,
            };
        },
        getMany: async ({ resource, ids }) => {
            let url = `${ADMIN_BFF_URL}/${resource}`;
            if (ids.length === 1) {
                url = `${ADMIN_BFF_URL}/${resource}/${ids[0]}`;
            }
            const { data } = await axios.get(url, config);
            return {
                data,
            };
        },
        create: async ({ resource, variables, metaData }) => {
            const url = metaData?.URLSuffix
                ? `${ADMIN_BFF_URL}/${resource}/${metaData.URLSuffix}`
                : `${ADMIN_BFF_URL}/${resource}/`;

            const { data } = await axios.post(url, variables, config);

            return {
                data,
            };
        },
        update: async ({ resource, id, variables, metaData }) => {
            const url = metaData?.URLSuffix
                ? `${ADMIN_BFF_URL}/${resource}/${id}/${metaData.URLSuffix}`
                : `${ADMIN_BFF_URL}/${resource}/${id}`;

            const { data } = metaData?.URLSuffix
                ? await axios.post(url, config)
                : await axios.put(url, variables, config);

            return {
                data,
            };
        },

        deleteOne: async ({ resource, id, metaData }) => {
            let url = metaData?.URLSuffix
                ? `${ADMIN_BFF_URL}/${resource}/${id}/${metaData.URLSuffix}`
                : `${ADMIN_BFF_URL}/${resource}/${id}`;

                if (metaData?.query) {
                    const newUrl = new URL(url);
                    for (const [key, value] of Object.entries(metaData?.query)) {
                        newUrl.searchParams.set(key, value as string)
                    }
                    url = newUrl.toString();
                }

            const { data } = await axios.delete(url, config);

            return {
                data,
            };
        },
    };
};