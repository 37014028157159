import {
  Form,
  Input,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";

export const OriginsForm: React.FC<MyFormProps> = ({formProps}) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Descrição"
        name="Description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tag"
        name="Tag"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );

}