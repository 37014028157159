import { useApiUrl, useCustom } from "@pankod/refine-core";
import { BFF_KEY } from "environment";

interface ITouchedLivesReponse {
    _id: string;
    totalAmount: number;
}

export const TotalTouchedLives: React.FC = () => {
    const apiUrl = useApiUrl();
    
    const { data } = useCustom<ITouchedLivesReponse[]>({
        url: `${apiUrl}/v1/dashboard/total-touched-lives/`,
        method: "get",
        config: {
            headers: {
                'Content-Type': 'application/json',
                'ocp-apim-subscription-key': BFF_KEY
            },
        },
    });

    const value = data?.data[0].totalAmount;
    
    return(
        <p style={{textAlign: "center", fontSize: 80, fontWeight: 700, color: "#4090f0"}}>
            {value}
        </p>);
}