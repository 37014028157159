import {
    Form,
    Input,
    Select,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";
import { IRole } from "interfaces";

export const UsersForm: React.FC<MyFormProps> = ({formProps}) => {
    const roles: Array<IRole> = [
        {
            value: "ADMIN",
            label: "Administrador do Sistema"
        },
        {
            value: "REPORTS_FULL",
            label: "Relatórios Completo"
        },
        {
            value: "REPORTS_BASIC",
            label: "Relatórios Básico"
        },
    ];
    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Nome"
                name="name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="E-Mail"
                name="email"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Senha"
                name="secret"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Perfil"
                name="role"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select 
                    options={roles}
                />
            </Form.Item>
        </Form>
    );
}