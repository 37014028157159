import {
    Form,
    Input,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";

export const HealthInsurerForm: React.FC<MyFormProps> = ({formProps}) => {
    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Id da Operadore"
                name="HealthInsurerId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Nome da Operadora"
                name="Description"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );

}