import { Alert, Card } from "@pankod/refine-antd";


export function CacheNotificationCard() {
    return (
        <Alert 
            message="Aviso de Cache" 
            description="Devido ao serviço de cache, as informações cadastradas nesta tela podem não se refletirem imediatamente no Contrate On-line. Para atualização imediata utilizar o menu Gestão de Cache!"
            type="info" 
            showIcon
        />
    )
}