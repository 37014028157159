import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";
import { IHolidays } from "interfaces";
import { HolidaysForm } from "components/forms";

export const HolidaysCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IHolidays>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <HolidaysForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Create>
  );
};
