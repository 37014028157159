import { create } from 'zustand';
import { persist } from 'zustand/middleware'

type CampaignOption = {
    value: string;
    label: string;
}

export type CampaignState = {
    selectedCampaignOption: CampaignOption | null;
}

export type CampaignActions = {
    setSelectedCampaignOption(data: CampaignOption): void;
}

export type CampaignStore = CampaignState & CampaignActions;

export const useCampaignStore = create(persist<CampaignStore>((set) => ({
    selectedCampaignOption: null,
    setSelectedCampaignOption: (data) => set({ selectedCampaignOption: data }),
}), { name: 'campaign-store' }))

export const selectSelectedCampaignOption = (store: CampaignStore) => store.selectedCampaignOption;
export const selectSetSelectedCampaignOption = (store: CampaignStore) => store.setSelectedCampaignOption;