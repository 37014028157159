import {
    Form,
    Input,
} from "@pankod/refine-antd";
import { Colorpicker } from 'antd-colorpicker';
import { MyFormProps } from ".";

export const PlansFamilyForm: React.FC<MyFormProps> = ({ formProps }) => {
    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Nome"
                name="Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Descrição"
                name="Description"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <textarea
                    className="ant-input"
                    placeholder="Informe a descrição da família do plano..."
                    rows={3}
                ></textarea>
            </Form.Item>
            <Form.Item
                label="Cor"
                name="Color"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Colorpicker popup onColorResult={(color) => color.hex} />
            </Form.Item>
            <Form.Item
                label="Palavras-chaves"
                name="KeyWords"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input
                    placeholder="Informe as palavras-chave do plano (separados por ';')..."
                />
            </Form.Item>
            <Form.Item
                label="Benefícios Saúde"
                name="BenefictsHealth"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <textarea
                    className="ant-input"
                    placeholder="Informe os benefícios da família do plano (separados por ';')..."
                    rows={3}
                ></textarea>
            </Form.Item>
            <Form.Item
                label="Benefícios Odonto"
                name="BenefictsOdontology"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <textarea
                    className="ant-input"
                    placeholder="Informe os benefícios da família do plano (separados por ';')..."
                    rows={3}
                ></textarea>
            </Form.Item>
        </Form>
    );

}