import { IResourceComponentsProps, useModal } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Button,
  Icons,
  Modal,
} from "@pankod/refine-antd";
import { IHealthInsurer } from "interfaces";
import { useState } from "react";
import { ModalHelthInsurer2BranchOffice } from "components/modals";

export const HealthInsurerList: React.FC<IResourceComponentsProps> = () => {

  const { tableProps, sorter } = useTable<IHealthInsurer>({
    initialSorter: [
      {
        field: "HealthInsurerId",
        order: "asc",
      },
    ],
    initialCurrent: 1,
    initialPageSize: 100,
    hasPagination: true,
  });

  const [record, setRecord] = useState<IHealthInsurer>();
  const {
    show: branchOfficesShow,
    visible: branchOfficesVisible,
    close: branchOfficesClose
  } = useModal();

  return (
    <>
      <List canCreate={true}>
        <Table {...tableProps} rowKey="HealthInsurerId">
          <Table.Column
            dataIndex="HealthInsurerId"
            key="HealthInsurerId"
            title="Id da Operadora"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("HealthInsurerId", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="Description"
            key="Description"
            title="Nome da Operadora"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("Description", sorter)}
            sorter
          />
          <Table.Column<IHealthInsurer>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Button
                  size="small"
                  icon={<Icons.BranchesOutlined />}
                  onClick={() => {
                    setRecord(record);
                    branchOfficesShow();
                  }}
                >
                  Filiais
                </Button>
                <EditButton hideText size="small" recordItemId={record.HealthInsurerId} />
                <ShowButton hideText size="small" recordItemId={record.HealthInsurerId} />
                <DeleteButton hideText size="small" recordItemId={record.HealthInsurerId} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        visible={branchOfficesVisible}
        onCancel={branchOfficesClose}
        destroyOnClose={true}
        width={840}
        title={"Filiais da operadora " + record?.Description}
        footer={null}
      >
        <ModalHelthInsurer2BranchOffice
          health_insurer={record}
        />
      </Modal>
    </>
  );
};
