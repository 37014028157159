import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm,
} from "@pankod/refine-antd";
import { ISellers } from "interfaces";
import { SellersForm } from "components/forms";

export const SellersEdit: React.FC<IResourceComponentsProps> = () => {

  const { formProps, saveButtonProps, queryResult } = useForm<ISellers>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <SellersForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
