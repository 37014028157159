import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm,
} from "@pankod/refine-antd";
import { IHealthInsurer } from "interfaces";
import { HealthInsurerForm } from "components/forms";

export const HealthInsurerEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IHealthInsurer>();
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <HealthInsurerForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
