import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm,
} from "@pankod/refine-antd";
import { IOrigins } from "interfaces";
import { OriginsForm } from "components/forms";

export const OriginsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IOrigins>();
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <OriginsForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
