import { ResourceProps } from "@pankod/refine-core";
import "@pankod/refine-antd/dist/styles.min.css";

import { UsersList, UsersCreate, UsersEdit } from "pages/users";
import { HealthInsurerList, HealthInsurerCreate, HealthInsurerEdit, HealthInsurerShow } from "pages/health-insurer";
import { OriginsList, OriginsCreate, OriginsEdit, OriginsShow } from "pages/origins";
import { ParametrizationsList, ParametrizationsCreate, ParametrizationsEdit, ParametrizationsShow } from "pages/parametrizations";
import { FileList } from "pages/file-manager";
import { PlansFamilyList, PlansFamilyCreate, PlansFamilyEdit, PlansFamilyShow } from "pages/plans-family";
import { SellersList, SellersCreate, SellersEdit, SellersShow } from "pages/sellers";
import { BranchOfficesList, BranchOfficesCreate, BranchOfficesEdit, BranchOfficesShow } from "pages/branch-offices";
import { PresencePointsList, PresencePointsCreate, PresencePointsEdit, PresencePointsShow } from "pages/presence-points";
import { BudgetsList, BudgetsShow } from "pages/budgets";
import { ContentsList, ContentsCreate, ContentsEdit, ContentsShow } from "pages/cms";
import { HolidaysCreate, HolidaysEdit, HolidaysList, HolidaysShow } from "pages/holidays";
import { RedisList, RedisShow } from "pages/cache-redis";

import { USER_DATA } from "environment";
import { ControlPanel } from "pages/control-panel";
import { CampaignCreate, CampaignEdit, CampaignList, CampaignShow } from "pages/campaign";

export const resourcesProvider = (): ResourceProps[] => {
    let role = "";
    const userdata = localStorage.getItem(USER_DATA);
    if (userdata) {
        const parsedUser = JSON.parse(userdata);
        role = parsedUser.roles ?? "REPORTS_BASIC";
    }

    // Criar criterios de acesso por perfil

    let resourcesList = [];

    if (role === "ADMIN") {
        resourcesList.push({
            name: "settings",
            options: { label: "Configurações" },
            //icon: Icons.SettingOutlined,
        });
        resourcesList.push({
            name: "v1/health-insurer", parentName: "settings",
            options: { label: "Operadoras", route: "health-insurer" },
            list: HealthInsurerList, create: HealthInsurerCreate, edit: HealthInsurerEdit, show: HealthInsurerShow
        });
        resourcesList.push({
            name: "v1/campaign", parentName: "settings",
            options: { label: "Campanhas", route: "campaign" },
            list: CampaignList, create: CampaignCreate, edit: CampaignEdit, show: CampaignShow
        });
        resourcesList.push({
            name: "v1/origin", parentName: "settings",
            options: { label: "Origens", route: "origins" },
            list: OriginsList, create: OriginsCreate, edit: OriginsEdit, show: OriginsShow
        });
        resourcesList.push({
            name: "v1/plan-family", parentName: "settings",
            options: { label: "Família de Planos", route: "plans-family" },
            list: PlansFamilyList, create: PlansFamilyCreate, edit: PlansFamilyEdit, show: PlansFamilyShow
        });
        resourcesList.push({
            name: "v1/branch-office", parentName: "settings",
            options: { label: "Filiais/Coberturas", route: "branch-offices" },
            list: BranchOfficesList, create: BranchOfficesCreate, edit: BranchOfficesEdit, show: BranchOfficesShow
        });
        resourcesList.push({
            name: "v1/presence-point", parentName: "settings",
            options: { label: "Rede de Cobertura", route: "presence-point" },
            list: PresencePointsList, create: PresencePointsCreate, edit: PresencePointsEdit, show: PresencePointsShow
        });
        resourcesList.push({
            name: "v1/budget", parentName: "reports",
            options: { label: "Orçamentos", route: "budgets" },
            list: BudgetsList, show: BudgetsShow
        });
        resourcesList.push({
            name: "v1/seller", parentName: "settings",
            options: { label: "Vendedores On-line", route: "sellers" },
            list: SellersList, create: SellersCreate, edit: SellersEdit, show: SellersShow
        });
        resourcesList.push({
            name: "v1/parametrization", parentName: "settings",
            options: { label: "Parametrizações", route: "parametrizations" },
            list: ParametrizationsList, create: ParametrizationsCreate, edit: ParametrizationsEdit, show: ParametrizationsShow
        });
        resourcesList.push({
            name: "v1/cms", parentName: "settings",
            options: { label: "Gestão de Conteúdo", route: "cms" },
            list: ContentsList, create: ContentsCreate, edit: ContentsEdit, show: ContentsShow
        });
        resourcesList.push({
            name: "v1/cms-files", parentName: "settings",
            options: { label: "Porta Arquivos", route: "files" },
            list: FileList
        });        
        resourcesList.push({
            name: "v1/holidays", parentName: "settings",
            options: { label: "Feriados", route: "holidays" },
            list: HolidaysList, create: HolidaysCreate, edit: HolidaysEdit, show: HolidaysShow
        });
    }

    if (role === "ADMIN" ||
        role === "REPORTS_FULL" ||
        role === "REPORTS_BASIC") {
        resourcesList.push({
            name: "reports",
            options: { label: "Relatórios" },
            //icon: Icons.PrinterOutlined,
        });
        resourcesList.push({
            name: "v1/budget", parentName: "reports",
            options: { label: "Orçamentos", route: "budgets" },
            list: BudgetsList, show: BudgetsShow
        });
    }

    if (role === "ADMIN") {
        resourcesList.push({
            name: "systemAdmin",
            options: { label: "Gestão do Sistema" },
            //icon: Icons.CrownOutlined,
        });
        resourcesList.push({
            name: "v1/users", parentName: "systemAdmin",
            options: { label: "Usuários", route: "users" },
            list: UsersList, create: UsersCreate, edit: UsersEdit, show: OriginsShow
        });
        resourcesList.push({
            name: "v1/redis", parentName: "systemAdmin",
            options: { label: "Gestão de Cache", route: "redis" },
            list: RedisList, show: RedisShow
        });
        resourcesList.push({
            name: "v1/xpto", parentName: "systemAdmin",
            options: { label: "Painel de Controle", route: "control-panel" },
            list: ControlPanel
        });
    }

    if (resourcesList.length === 0) {
        resourcesList.push({ name: "" });
    }

    return resourcesList;
}