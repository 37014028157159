import { IResourceComponentsProps, useApiUrl, useNotification } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  DeleteButton,
  TagField,
  ShowButton,
  Button,
  Card,
  Form,
  Icons,
  Select,
} from "@pankod/refine-antd";
import { ICacheRedis } from "interfaces";
import { selectApiName, selectSetApiName, useCacheRedisStore } from "./store";
import { BFF_KEY } from "environment";

export const RedisList: React.FC<IResourceComponentsProps> = () => {
  const setApiName = useCacheRedisStore(selectSetApiName);
  const apiName = useCacheRedisStore(selectApiName);
  const { tableProps, searchFormProps, sorter, tableQueryResult } = useTable<ICacheRedis>({
    initialSorter: [
      {
        field: "key",
        order: "asc",
      },
    ],
    initialFilter: [
      {
        field: "api",
        operator: "eq",
        value: "admin"
      }
    ],
    onSearch: (values: any) => {
      return [
        {
          field: "api",
          operator: "eq",
          value: values.api_name ?? 'admin',
        }
      ];
    },
  });

  const apiUrl = useApiUrl();

  const toast = useNotification();

  async function handleFlushRedis() {
    fetch(`${apiUrl}/v1/redis/flushdb/${apiName}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': BFF_KEY,
        },
    }).then(() => {
      toast.open?.({
        message: `cache ${apiName} resetado com sucesso`,
        type: 'success',
      })
    }).catch(() => {
      toast.open?.({
        message: `ocorreu um erro ao tentar resetar o cache ${apiName}, tente novamente mais tarde.`,
        type: 'error',
      })
    }).finally(() => {
      tableQueryResult.refetch();
    });
  }

  return (
    <List>
      <Card title="Busca">
        <div style={{ display: 'flex' }}>
        <Form {...searchFormProps} layout="inline">
          <Space>
            <Form.Item name="api_name" label="Selecione uma API:">
              <Select
                defaultValue='admin'
                onChange={setApiName}
                options={[
                    { value: 'admin', label: <span>Admin</span> },
                    { value: 'budget', label: <span>Budget</span> },
                  ]}
                style={{ width: "80px" }} />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<Icons.SearchOutlined />}
              onClick={searchFormProps.form?.submit}
            >
              Buscar
            </Button>
          </Space>
        </Form>
        <Button
            size="small"
            type="primary"
            icon={<Icons.ClearOutlined />}
            onClick={handleFlushRedis}
            style={{ marginLeft: 'auto' }}
          >
          Reset Cache
        </Button>
        </div>
      </Card>
      <Table {...tableProps} rowKey="key">
        <Table.Column
          dataIndex="key"
          key="key"
          title="Chave"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("key", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="ttl"
          key="ttl"
          title="Expiração"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("ttl", sorter)}
          sorter
        />

        <Table.Column<ICacheRedis>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.key} />
              <DeleteButton hideText size="small" recordItemId={record.key} metaData={{ query: { api: apiName } }} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
