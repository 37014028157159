import {
  Table,
  TextField,
  useTable
} from "@pankod/refine-antd";
import { IResponse } from ".";

export const Top10HelathInsurer: React.FC = () => {

  const { tableProps } = useTable<IResponse>({
    resource: "v1/dashboard/top-10-health-insurer",
    hasPagination: false, 
  });

  return (
    <Table
      {...tableProps}
      rowKey="_id"
      pagination={{ ...tableProps.pagination, hideOnSinglePage: true }}
      showHeader={true}
    >
      <Table.Column<IResponse>
        dataIndex="_id"
        key="_id"
        title="Operadora"
        render={(value) => (<TextField value={value} />)}
      />
      <Table.Column<IResponse>
        dataIndex="count"
        key="count"
        title="Qtde. Orçamentos"
        render={(value) => (<TextField value={value} />)}
      />
    </Table>
  );
}