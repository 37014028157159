import {
    Form,
    Input,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";

export const BranchOfficesForm: React.FC<MyFormProps> = ({formProps}) => {
    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Id da Área"
                name="AreaId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Nome da Área"
                name="AreaDescription"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Estado"
                name="Uf"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Id Filial"
                name="BranchOfficeId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );

}