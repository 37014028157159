import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography, Tag } from "@pankod/refine-antd";

import { IPlansFamily } from "interfaces";

const { Title, Text } = Typography;

export const PlansFamilyShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IPlansFamily>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Nome</Title>
      <Text>{record?.Name}</Text>

      <Title level={5}>Descrição</Title>
      <Text>{record?.Description}</Text>

      <Title level={5}>Cor</Title>
      <Text><Tag color={record?.Color}>{record?.Color}</Tag></Text>

      <Title level={5}>Palavras-chaves</Title>
      <Text>{record?.KeyWords}</Text>

      <Title level={5}>Benefícios Saúde</Title>
      <Text>{record?.BenefictsHealth}</Text>

      <Title level={5}>Benefícios Odonto</Title>
      <Text>{record?.BenefictsOdontology}</Text>
    </Show>
  );
};
