import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm
} from "@pankod/refine-antd";
import { IPlansFamily } from "interfaces";
import { PlansFamilyForm } from "components/forms";

export const PlansFamilyEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IPlansFamily>();
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <PlansFamilyForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
