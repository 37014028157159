import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { HttpError, useApiUrl, useCustom } from '@pankod/refine-core';
import { BFF_KEY } from 'environment';
import { IResponse } from '.';

export const ChartBudgetCount: React.FC = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const apiUrl = useApiUrl();

    const { data } = useCustom<IResponse[], HttpError>({
        url: `${apiUrl}/v1/dashboard/total-registered-budget-last-x-days/`,
        method: "get",
        config: {
            headers: {
                'Content-Type': 'application/json',
                'ocp-apim-subscription-key': BFF_KEY
            },
            filters: [
                {
                    field: "days",
                    operator: "eq",
                    value: "7"
                }
            ]
        },
    });

    const response = data?.data || [];

    let labels = response.map((el) => {return el._id});
    let values = response.map((el) => {return el.count});
  
    const chartConfig = {
        labels: labels,
        datasets: [
            {
                label: 'Orçamentos',
                data: values,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderColor: 'blue',
                borderWidth: 1
            },
        ],
    };

    return (<Bar data={chartConfig} />);  
}