import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type CacheRedisState = {
    apiName: string;
}

type CacheRedisActions = {
    setApiName: (name: string) => void;
}

type CacheRedisStore = CacheRedisState & CacheRedisActions;

export const useCacheRedisStore = create(persist<CacheRedisStore>((set) => ({
    apiName: 'admin',
    setApiName: (name) => set({ apiName: name }),
}), { name: 'cache-redis-store' }));

export const selectApiName = (store: CacheRedisStore) => store.apiName;
export const selectSetApiName = (store: CacheRedisStore) => store.setApiName;