import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
} from "@pankod/refine-antd";
import { IParametrizations } from "interfaces";

export const ParametrizationsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IParametrizations>({
    initialSorter: [
      {
        field: "Key",
        order: "asc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="Key">
        <Table.Column
          dataIndex="Key"
          key="Key"
          title="Chave"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Key", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Value"
          key="Value"
          title="Valor"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Value", sorter)}
          sorter
        />
        
        <Table.Column<IParametrizations>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.Key} />
              <ShowButton hideText size="small" recordItemId={record.Key} />
              <DeleteButton hideText size="small" recordItemId={record.Key} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
