import { IResourceComponentsProps, useModal } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  Modal,
  Icons,
  Button,
} from "@pankod/refine-antd";
import { IPlansFamily } from "interfaces";
import { ModalPlansFamilyParams } from "components/modals";
import { useState } from "react";

export const PlansFamilyList: React.FC<IResourceComponentsProps> = () => {

  const { tableProps, sorter } = useTable<IPlansFamily>({
    initialSorter: [
      {
        field: "FamilyId",
        order: "asc",
      },
    ],
  });

  const [record, setRecord] = useState<IPlansFamily>();
  const {
    show: plansFamilyParamsShow,
    visible: plansFamilyParamsVisible,
    close: plansFamilyParamsClose
  } = useModal();

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="PlansFamilyId">
          <Table.Column
            dataIndex="Name"
            key="Name"
            title="Nome"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("Name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="Description"
            key="Description"
            title="Descrição"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("Description", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="Color"
            key="Color"
            title="Cor"
            render={(value) => <TagField value={value} color={value} />}
            defaultSortOrder={getDefaultSortOrder("Color", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="KeyWords"
            key="KeyWords"
            title="Palavras-chaves"
            render={(value) => <TagField value={value} />}
            defaultSortOrder={getDefaultSortOrder("KeyWords", sorter)}
            sorter
          />

          <Table.Column<IPlansFamily>
            title="Ações"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Button
                  size="small"
                  icon={<Icons.CodeOutlined />}
                  onClick={() => {
                    setRecord(record);
                    plansFamilyParamsShow();
                  }}
                >
                  Parâmetros
                </Button>
                <EditButton hideText size="small" recordItemId={record.FamilyId} />
                <ShowButton hideText size="small" recordItemId={record.FamilyId} />
                <DeleteButton hideText size="small" recordItemId={record.FamilyId} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        visible={plansFamilyParamsVisible}
        onCancel={plansFamilyParamsClose}
        destroyOnClose={true}
        width={840}
        title={"Parametros da família " + record?.Name}
        footer={null}
      >
        <ModalPlansFamilyParams
          plan_family={record}
        />
      </Modal>
    </>
  );
};
