import {
    List,
    Table,
    TextField,
    useTable,
    Space,
    Button,
    Icons,
    PageHeaderProps,
    BooleanField,
    Card,
    Form,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { useApiUrl } from "@pankod/refine-core";
import { BFF_KEY } from "environment";
import { IBranchOfficesSearch, IHealthInsurer, IHealthInsurerBranchOffice } from "interfaces";

export type Hi2BoProps = {
    health_insurer: IHealthInsurer | undefined;
};

export const ModalHelthInsurer2BranchOffice: React.FC<Hi2BoProps> = ({ health_insurer }) => {

    const pageHeaderProps: PageHeaderProps = {
        title: "Selecione as filiais...",
        breadcrumb: undefined,
    };

    const { selectProps } = useSelect<IBranchOfficesSearch>({
        resource: "v1/branch-office/distinct-ids",
        optionLabel: "branch_id",
        optionValue: "branch_id",
        hasPagination: false
      });

    const {
        tableProps,
        tableQueryResult,
        searchFormProps,
    } = useTable<IHealthInsurerBranchOffice>({
        resource: `v1/health-insurer/${health_insurer?.HealthInsurerId}/branch-office`,
        initialCurrent: 1,
        initialPageSize: 20,
        hasPagination: true,
        onSearch: (values: any) => {
            return [
              {
                field: "branch_id",
                operator: "eq",
                value: values.branch_id,
              }
            ];
          },
    });

    const apiUrl = useApiUrl();

    const addBranchOffice = async (AreaId: number) => {
        await fetch(`${apiUrl}/v1/health-insurer/branch-office`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'ocp-apim-subscription-key': BFF_KEY
            },
            body: JSON.stringify(
                {
                    "HealthInsurerId": health_insurer?.HealthInsurerId,
                    "AreaId": AreaId
                }
            )
        }).then(data => data.json());
    };

    const delBranchOffice = async (AreaId: number) => {
        await fetch(`${apiUrl}/v1/health-insurer/${health_insurer?.HealthInsurerId}/branch-office/${AreaId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'ocp-apim-subscription-key': BFF_KEY
            },
        }).then(data => data.json());
    };

    return (
        <>
            <Card title="Busca">
                <Form {...searchFormProps} layout="inline">
                <Space>
                    <Form.Item name="branch_id" label="Código da Filial:">
                    <Select {...selectProps} style={{ width: "80px" }} />
                    </Form.Item>
                    <Button
                    size="small"
                    type="primary"
                    icon={<Icons.SearchOutlined />}
                    onClick={() => {
                        searchFormProps.form?.submit();
                    }}
                    >
                    Buscar
                    </Button>
                </Space>
                </Form>
            </Card>
            <List
                headerProps={pageHeaderProps}
                canCreate={false}
            >
                <Table {...tableProps} rowKey="AreaId" size="small">
                    <Table.Column
                        dataIndex="AreaId"
                        key="AreaId"
                        title="Id da Área"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        dataIndex="AreaDescription"
                        key="AreaDescription"
                        title="Filial"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        dataIndex="Uf"
                        key="Uf"
                        title="Uf"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        dataIndex="BranchOfficeId"
                        key="BranchOfficeId"
                        title="Id da Filial"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column<IHealthInsurerBranchOffice>
                        key="HealthInsurerId"
                        dataIndex="HealthInsurerId"
                        title="Vinculada?"
                        render={(value, data: any) => {
                            
                            return <BooleanField
                                value={value != null}
                                trueIcon={<Icons.CheckOutlined />}
                                falseIcon={<Icons.CloseOutlined />}
                                valueLabelTrue="Sim"
                                valueLabelFalse="Não"
                            />;
                        }}
                    />
                    <Table.Column<IHealthInsurerBranchOffice>
                        title="Ações"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <Button
                                    size="middle"
                                    icon={<Icons.SwapOutlined />}
                                    onClick={() => {
                                        if (record.HealthInsurerId == null) {
                                            addBranchOffice(record.AreaId).then(() => {
                                                tableQueryResult.refetch();
                                            });
                                        } else {
                                            delBranchOffice(record.AreaId).then(() => {
                                                tableQueryResult.refetch();
                                            });
                                        }
                                    }}
                                    style={{ textAlign: "right" }}
                                />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
    );
}