import {
    Form,
    Input,
    Select,
    useSelect,
} from "@pankod/refine-antd";
import { IOrigins } from "interfaces";
import { MyFormProps } from ".";

export const SellersForm: React.FC<MyFormProps> = ({ formProps, queryResult }) => {

    const { selectProps: originsSelectProps } = useSelect<IOrigins>({
        resource: "v1/origin",
        optionLabel: "Description",
        optionValue: "OriginId",
        defaultValue: queryResult?.data?.data.OriginId,
    });

    return (
        <Form {...formProps} layout="vertical">
            <Form.Item
                label="Origem"
                name="OriginId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...originsSelectProps} />
            </Form.Item>
            <Form.Item
                label="Filial"
                name="BranchOfficeId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Código do Vendedor"
                name="SellerId"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Nome do Vendedor"
                name="SellerName"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );

}