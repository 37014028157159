import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm,
} from "@pankod/refine-antd";
import { IParametrizations } from "interfaces";
import { ParametrizationsForm } from "components/forms";

export const ParametrizationsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IParametrizations>();
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <ParametrizationsForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
