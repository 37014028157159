import { AuthProvider } from "@pankod/refine-core";
import { TOKEN_KEY, USER_DATA, ADMIN_BFF_URL, BFF_KEY } from "./environment";
import { AxiosInstance } from "axios";

export interface IUserData {
    id: string;
    name: string;
    roles: string;
    lastLogin: string
}

const config = {
    headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': BFF_KEY,
    }
};

export const authProvider = (axiosInstance: AxiosInstance): AuthProvider => {
    return {
        login: async ({ email, secret }) => {
            let UserId = 0;
            try {
                const { data } = await axiosInstance.post(`${ADMIN_BFF_URL}/v1/auth/token`, {
                    email,
                    secret
                }, config);
                localStorage.setItem(TOKEN_KEY, data.token);
                UserId = data.UserId;
            } catch (error) {
                return Promise.reject(error);
            }

            const { data } = await axiosInstance.get(`${ADMIN_BFF_URL}/v1/users/` + UserId, config);

            let actualDate = new Date();

            const user: IUserData = {
                id: data.id,
                name: data.name,
                roles: data.role,
                lastLogin: actualDate.toISOString().split('T')[0]
            };

            localStorage.setItem(USER_DATA, JSON.stringify(user));

            return Promise.resolve("/");
        },
        logout: () => {
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(USER_DATA);
            return Promise.resolve();
        },
        checkError: (error) => {
            if (error?.response?.status === 401) {
                return Promise.reject("/register");
            }
            return Promise.resolve();
        },
        checkAuth: () => {
            const token = localStorage.getItem(TOKEN_KEY);
            const userdata = localStorage.getItem(USER_DATA);
            if (token && userdata) {
                const user: IUserData = JSON.parse(userdata);
                let newDate = new Date();
                let date = newDate.toISOString().split('T')[0];
                if (date === user.lastLogin)
                    return Promise.resolve();
                localStorage.removeItem(TOKEN_KEY);
                localStorage.removeItem(USER_DATA);
            }
            return Promise.reject();
        },
        getPermissions: () => {
            const userdata = localStorage.getItem(USER_DATA);
            if (userdata) {
                const parsedUser = JSON.parse(userdata);
                return Promise.resolve(parsedUser.roles);
            }
            return Promise.reject();
        },
        getUserIdentity: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            const userdata = localStorage.getItem(USER_DATA);
            if (!token || !userdata) {
                return Promise.reject();
            }

            const user: IUserData = JSON.parse(userdata);

            return Promise.resolve({
                id: user.id,
                name: user.name,
                roles: user.roles,
                avatar: "/images/user-icon.png",
            });
        },
    };
};