import "./style.less";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    return (
        <div className="logo">
            {collapsed ? (
                <img src="/images/hapvida-ico.png" alt="Hapvida" />
            ) : (
                <img src="/images/hapvida-logo.png" alt="Hapvida" />
            )}
        </div>
    );
};