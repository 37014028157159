import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Collapse, Show, Typography } from "@pankod/refine-antd";

import { IBudgets } from "interfaces";

const { Title, Text } = Typography;

export const BudgetsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IBudgets>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { Panel } = Collapse;
  const collapseStyle = {
    marginBottom: "1rem"
  };
  console.log("record: ", record); 

  return (
    <Show isLoading={isLoading}>
      <Title level={4}>Dados Pessoais</Title>
      <Collapse style={collapseStyle}>
        <Panel header="Dados pessoais" key='1'>
          <Title level={5}>Nome</Title>
          <Text>{record?.Holder.Name}</Text>
          <Title level={5}>Cpf</Title>
          <Text>{record?.Holder.Cpf}</Text>
          <Title level={5}>Email</Title>
          <Text>{record?.Holder.Email}</Text>
          <Title level={5}>Telefone</Title>
          <Text>{record?.CellPhone}</Text>
          <Title level={5}>Data de Nascimento</Title>
          <Text>{record?.Holder.HolderBornDate}</Text>
          <Title level={5}>Nome da Mãe</Title>
          <Text>{record?.Holder.MotherName}</Text>
          <Title level={5}>Gênero</Title>
          <Text>{record?.Holder.Gender}</Text>
          <Title level={5}>Estado Civil</Title>
          <Text>{record?.Holder.CivilState}</Text>
        </Panel>
      </Collapse>
      <Title level={4}>Documentação</Title>
      <Collapse style={collapseStyle}>
        <Panel header="Documentação" key='2'>
          <Title level={5}>Cód. Tipo de Documento</Title>
          <Text>{record?.Holder.DocTypeCod}</Text>
          <Title level={5}>Desc. Tipo de Documento</Title>
          <Text>{record?.Holder.DocType}</Text>
          <Title level={5}>Número do Documento</Title>
          <Text>{record?.Holder.DocNumber}</Text>
          <Title level={5}>Órgão Emissor do Documento</Title>
          <Text>{record?.Holder.Issuer}</Text>
          <Title level={5}>UF de Emissão do Documento</Title>
          <Text>{record?.Holder.IssuerFU}</Text>
        </Panel>
      </Collapse>
      <Title level={4}>Dados Residenciais</Title>
      <Collapse style={collapseStyle}>
        <Panel header="Dados Residenciais" key='3'>
          <Title level={5}>CEP</Title>
          <Text>{record?.Address?.Cep}</Text>
          <Title level={5}>Logradouro</Title>
          <Text>{record?.Address?.Place}</Text>
          <Title level={5}>Complemento</Title>
          <Text>{record?.Address?.Complement}</Text>
          <Title level={5}>Bairro</Title>
          <Text>{record?.Address?.District}</Text>
          <Title level={5}>Número</Title>
          <Text>{record?.Address?.Number}</Text>
          <Title level={5}>Cidade</Title>
          <Text>{record?.Address?.City}</Text>
          <Title level={5}>Estado</Title>
          <Text>{record?.Address?.State}</Text>
        </Panel>
      </Collapse>
      <Title level={4}>Operadora e Produto</Title>
      <Collapse style={collapseStyle}>
        <Panel header="Operadora e Produto" key='4'>
          <Title level={5}>Operadora</Title>
          <Text>{record?.HealthInsurer}</Text>
          <Title level={5}>Cidade</Title>
          <Text>{record?.BranchName}</Text>
          <Title level={5}>Estado</Title>
          <Text>{record?.AreaState}</Text>
          <Title level={5}>Data de Criação</Title>
          <Text>{record?.CreatedAt}</Text>
          <Title level={5}>Número de Controle</Title>
          <Text>{record?.ControlNumber}</Text>
          <Title level={5}>Produto</Title>
          <Text>{record?.PlanBaseCode}</Text>
        </Panel>
      </Collapse>
      <Title level={4}>Vidas: <Text>{record?.DependentNumber}</Text></Title>
      <Text>{
        record?.Dependents?.map((dep, index) => {
          const i = index + 1;
          const header = "Dados do dependente " + i;
          return (
            <>
              <Collapse style={collapseStyle}>
                <Panel header={header} key={i + 4}>
                  <Title level={5}>Nome: </Title><Text>{dep.FullName}</Text>
                  <Title level={5}>CPF: </Title><Text>{dep.Cpf}</Text>
                  <Title level={5}>Data de Nascimento: </Title><Text>{dep.DependentBornDate}</Text>
                  <Title level={5}>Cód. Parentesco</Title><Text>{dep.RelationshipCode}</Text>
                  <Title level={5}>Desc. Parentesco: </Title><Text>{dep.RelationshipDescription}</Text>
                  <Title level={5}>Gênero: </Title><Text>{dep.Gender}</Text>
                  <Title level={5}>Nome da mãe: </Title><Text>{dep.MotherName}</Text>
                  <Title level={5}>Cód. Tipo Documento: </Title><Text>{dep.DocTypeCod}</Text>
                  <Title level={5}>Desc. Tipo Documento: </Title><Text>{dep.DocType}</Text>
                  <Title level={5}>Número do Documento: </Title><Text>{dep.DocNumber}</Text>
                  <Title level={5}>Órgão Emissor do Documento: </Title><Text>{dep.Issuer}</Text>
                  <Title level={5}>UF de Emissão do Documento: </Title><Text>{dep.IssuerFU}</Text>
                </Panel>
              </Collapse>
            </>
          );
        })
      }
      </Text>
      <Title level={4}>Última Tela</Title>
      <Text>{record?.Screen}</Text>
      <Title level={4}>Origem</Title>
      <Text>{record?.Origin}</Text>
    </Show>
  );
};
