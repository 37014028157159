import { Refine } from "@pankod/refine-core";
import { notificationProvider, Layout, ReadyPage, ErrorComponent } from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";

// Ajustes no tema
import { Title } from "components";
import "styles/antd.less";

import { HomePage } from "pages/home";
import { LoginPage } from "pages/login";
import { authProvider } from "authProvider";
import { dataProvider } from "dataProvider";
import { resourcesProvider } from "resurcesProvider";

import { TOKEN_KEY } from "./environment";
import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;      
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  return request;
});

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      Title={Title}
      DashboardPage={HomePage}
      LoginPage={LoginPage}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={routerProvider}
      dataProvider={dataProvider(axiosInstance)}
      authProvider={authProvider(axiosInstance)}
      resources={resourcesProvider()}
      disableTelemetry={true}
    />
  );
}

export default App;
