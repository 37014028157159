import {
  DatePicker,
  Form,
  Input,
} from "@pankod/refine-antd";
import { MyFormProps } from ".";
import dayjs from "dayjs";

export const HolidaysForm: React.FC<MyFormProps> = ({ formProps }) => {

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Descrição"
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Data"
        name="date"
        getValueProps={(value) => ({
          value: value ? dayjs(value).hour(12) : "",
        })}
        rules={[{ required: true }]}>
        <DatePicker />
      </Form.Item>
    </Form>
  );

}