import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
} from "@pankod/refine-antd";
import { IOrigins } from "interfaces";

export const OriginsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IOrigins>({
    initialSorter: [
      {
        field: "OriginId",
        order: "asc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="OriginId">
        <Table.Column
          dataIndex="OriginId"
          key="OriginId"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("OriginId", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Description"
          key="Description"
          title="Descrição"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Description", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="Tag"
          key="Tag"
          title="Tag"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("Tag", sorter)}
          sorter
        />
        
        <Table.Column<IOrigins>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.OriginId} />
              <ShowButton hideText size="small" recordItemId={record.OriginId} />
              <DeleteButton hideText size="small" recordItemId={record.OriginId} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
