import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { IHealthInsurer } from "interfaces";

const { Title, Text } = Typography;

export const HealthInsurerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IHealthInsurer>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} canEdit={false}>
      <Title level={5}>Id Operadora</Title>
      <Text>{record?.HealthInsurerId}</Text>

      <Title level={5}>Nome da Operadora</Title>
      <Text>{record?.Description}</Text>
    </Show>
  );
};
