import { useApiUrl, useCustom } from "@pankod/refine-core";
import { BFF_KEY } from "environment";

export const TotalFinishedTodayBudget: React.FC = () => {
    const apiUrl = useApiUrl();

    const { data } = useCustom({
        url: `${apiUrl}/v1/dashboard/total-finished-today-budget/`,
        method: "get",
        config: {
            headers: {
                'Content-Type': 'application/json',
                'ocp-apim-subscription-key': BFF_KEY
            },
        },
    });
    
    const value = data?.data;

    return(
        <p style={{textAlign: "center", fontSize: 80, fontWeight: 700, color: "#4090f0"}}>
            {value}
        </p>);
}