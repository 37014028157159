import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";
import { IUsers } from "interfaces";
import { UsersForm } from "components/forms";

export const UsersCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IUsers>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <UsersForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Create>
  );
};
