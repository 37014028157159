import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  useForm,
} from "@pankod/refine-antd";
import { IPresencePoints } from "interfaces";
import { PresencePointsForm } from "components/forms";

export const PresencePointsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IPresencePoints>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <PresencePointsForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Create>
  );
};
