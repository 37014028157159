import {
    List,
    Table,
    TextField,
    Space,
    Button,
    PageHeaderProps,
    useEditableTable,
    Form,
    Input,
    SaveButton,
    EditButton,
    DeleteButton,
    useDrawerForm,
    Drawer,
    Create,
    DeleteButtonProps,
    Select,
} from "@pankod/refine-antd";
import { PlansFamilyParamsForm } from "components/forms";
import { IPlansFamily, IPlansFamilyParams } from "interfaces";
import { useState } from "react";

type PlansFamilyParamsProps = {
    plan_family: IPlansFamily | undefined;
};

export const ModalPlansFamilyParams: React.FC<PlansFamilyParamsProps> = ({ plan_family: plan_family }) => {

    const plans_family_id = plan_family?.FamilyId

    const pageHeaderProps: PageHeaderProps = {
        title: "Configure os parametros da familia: " + plan_family?.Name,
        breadcrumb: undefined,
    };

    const {
        tableProps,
        tableQueryResult,
        saveButtonProps: editSaveButtonProps,
        formProps,
        isEditing,
        setId,
        cancelButtonProps,
        editButtonProps
    } = useEditableTable<IPlansFamilyParams>({
        resource: "v1/plans-family-params",
        initialFilter: [
            {
                field: "plan_family_id",
                value: [plans_family_id],
                operator: "eq"
            }
        ],
        initialCurrent: 1,
        initialPageSize: 20,
        hasPagination: true
    });

    const [record, setRecord] = useState<IPlansFamilyParams>()

    const {
        drawerProps,
        formProps: drawerFormProps,
        saveButtonProps: drawerSaveButtonProps,
        show,
        close
    } = useDrawerForm<IPlansFamilyParams>({
        action: "create",
        resource: "v1/plans-family-params",
        redirect: false,
        onMutationSuccess: () => {
            close();
            tableQueryResult.refetch();
        }
    });

    const deleteButtonProps: DeleteButtonProps = {
        resourceNameOrRouteName: "v1/plans-family-params",
        onSuccess: () => {
            tableQueryResult.refetch();
        }
    }

    return (
        <>
            <List
                headerProps={pageHeaderProps}
                createButtonProps={{
                    onClick: () => {
                        show();
                    }
                }}
            >
                <Form {...formProps}>
                    <Table {...tableProps}
                        rowKey="_id"
                        size="small"
                        onRow={(record) => ({
                            onClick: (event: any) => {
                                if (event.target.nodeName === "TD") {
                                    setId && setId(record._id);
                                }
                            },
                        })}
                    >
                        <Table.Column<IPlansFamilyParams>
                            dataIndex="key"
                            key="key"
                            title="Chave"
                            render={(value, data: any) => {
                                if (isEditing(data._id)) {
                                    setRecord(data)
                                    return (
                                        <Form.Item
                                            name="key"
                                            style={{ margin: 0 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    );
                                }
                                return <TextField value={value} />;
                            }}
                        />
                        <Table.Column<IPlansFamilyParams>
                            dataIndex="variation"
                            key="variation"
                            title="Variação"
                            render={(value, data: any) => {
                                if (isEditing(data._id)) {
                                    setRecord(data)
                                    return (
                                        <Form.Item
                                            name="variation"
                                            style={{ margin: 0 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    );
                                }
                                return <TextField value={value} />;
                            }}
                        />
                        <Table.Column<IPlansFamilyParams>
                            dataIndex="type"
                            key="type"
                            title="Tipo"
                            render={(value, data: any) => {
                                if (isEditing(data._id)) {
                                    setRecord(data)
                                    return (
                                        <Form.Item
                                            name="type"
                                            style={{ margin: 0 }}
                                        >
                                            <Select
                                                options={
                                                    [
                                                        { value: 'texto', label: <span>Texto</span> },
                                                        { value: 'url', label: <span>URL</span> },
                                                    ]}
                                            />
                                        </Form.Item>
                                    );
                                }
                                return <TextField value={value} />;
                            }}
                        />
                        <Table.Column<IPlansFamilyParams>
                            dataIndex="value"
                            key="value"
                            title="Valor"
                            render={(value, data: any) => {
                                if (isEditing(data._id)) {
                                    setRecord(data)
                                    return (
                                        <Form.Item
                                            name="value"
                                            style={{ margin: 0 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    );
                                }
                                return <TextField value={value} />;
                            }}
                        />
                        <Table.Column<IPlansFamilyParams>
                            title="Ações"
                            dataIndex="actions"
                            render={(_text, record) => {
                                if (isEditing(record._id)) {
                                    return (
                                        <Space>
                                            <SaveButton
                                                {...editSaveButtonProps}
                                                size="small"
                                                hideText
                                            >
                                                Salvar
                                            </SaveButton>
                                            <Button
                                                {...cancelButtonProps}
                                                size="small"
                                            >
                                                X
                                            </Button>
                                        </Space>
                                    );
                                }
                                return (
                                    <Space>
                                        <EditButton {...editButtonProps(record._id)} hideText size="small" />
                                        <DeleteButton {...deleteButtonProps} recordItemId={record._id}
                                            hideText size="small"
                                        />
                                    </Space>
                                );
                            }}
                        />
                    </Table>
                </Form>
            </List>
            <Drawer
                {...drawerProps}
                destroyOnClose={true}
            >
                <Create
                    saveButtonProps={drawerSaveButtonProps}
                    title="Novo parâmetro"
                    breadcrumb={undefined}
                    headerButtons={undefined}
                    headerButtonProps={undefined}
                >
                    <PlansFamilyParamsForm
                        formProps={drawerFormProps}
                        plans_family_id={plans_family_id}
                    />
                </Create>
            </Drawer>
        </>
    );
}