import { IResourceComponentsProps, getDefaultFilter } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  Select,
  EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  useSelect,
  FilterDropdown
} from "@pankod/refine-antd";
import { IOrigins, ISellers } from "interfaces";

export const SellersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, filters } = useTable<ISellers>({
    initialSorter: [
      {
        field: "SellerId",
        order: "asc",
      },
    ],
    initialFilter: [
      {
        field: "OriginId",
        value: [1],
        operator: "eq",
      },
    ],
  });

  const { selectProps: originsSelectProps } = useSelect<IOrigins>({
    resource: "v1/origin",
    optionLabel: "Description",
    optionValue: "OriginId",
    defaultValue: getDefaultFilter("OriginId", filters, "eq"),
  });

  return (
    <List>
      <Table {...tableProps} rowKey="SellersId">
        <Table.Column
          dataIndex="OriginId"
          key="OriginId"
          title="Origem"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("OriginId", sorter)}
          sorter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                placeholder="Selecione a Origem"
                options={ originsSelectProps.options }
              />
            </FilterDropdown>
          )}
          defaultFilteredValue={ getDefaultFilter("OriginId", filters, "eq") }
        />
        <Table.Column
          dataIndex="AreaDescription"
          key="AreaDescription"
          title="Nome da Área"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("AreaDescription", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="BranchOfficeId"
          key="BranchOfficeId"
          title="Filial"
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("BranchOfficeId", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="SellerId"
          key="SellerId"
          title="#Vendedor"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("SellerId", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="SellerName"
          key="SellerName"
          title="Nome do Vendedor"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("SellerName", sorter)}
          sorter
        />
        <Table.Column<ISellers>
          title="Ações"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.SellerId} />
              <ShowButton hideText size="small" recordItemId={record.SellerId} />
              <DeleteButton hideText size="small" recordItemId={record.SellerId} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
