import { useLogin } from "@pankod/refine-core";
import { Row, Col, AntdLayout, Card, Typography, Form, Input, Button } from "@pankod/refine-antd";
import "./style.less";

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    secret: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const { mutate: login } = useLogin<ILoginForm>();
    const CardTitle = (
        <Title>
            <Col style={{ marginLeft: "2rem", marginTop: "2rem" }}>
                <img
                    src="/images/logo-hap.png"
                    width="168.7px"
                />
            </Col>
            <Col style={{ marginTop: "1rem", marginLeft: "2rem" }}>
                <Text
                    style={{
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "900",
                        fontSize: "30px",
                        lineHeight: "50px",
                        letterSpacing: "0.02em",
                        color: "#3C3C3C"
                    }}
                >
                    Painel do Administrador
                </Text>
            </Col>
            <Col style={{ marginTop: "-1rem", marginLeft: "2rem" }}>
                <Text
                    style={{
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "20px",
                        lineHeight: "25px",
                        letterSpacing: "0.02em",
                        color: "#3C3C3C"
                    }}
                >
                    Insira os seus dados para continuar
                </Text>
            </Col>
        </Title >
    );

    return (
        <AntdLayout
            style={{
                backgroundColor: "#003D85",
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh"
                }}
            >
                <Col style={{ width: "573px" }}>
                    <Card
                        title={CardTitle}
                        headStyle={{ borderBottom: 0, marginBottom: "-2rem" }}
                        style={{
                            borderRadius: "15px",
                        }}
                    >
                        <Col style={{ margin: "2rem" }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                            >
                                <Form.Item
                                    name="email"
                                    label={("E-mail *")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        style={{
                                            borderRadius: "5px",
                                            outline: "1px solid #003D85"
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="secret"
                                    label={("Senha *")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        type="password"
                                        size="large"
                                        style={{
                                            borderRadius: "5px",
                                            outline: "1px solid #003D85"
                                        }}
                                    />
                                </Form.Item>
                                {/*                            
                                <div style={{ marginTop: "0.5rem", marginBottom: "3rem", float: "right" }}>
                                    <a
                                        style={{
                                            fontFamily: 'Source Sans Pro',
                                            fontStyle: "normal",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            lineHeight: "20px",
                                            textDecorationLine: "underline",
                                            color: "#0054B8"
                                        }}
                                        href="#"
                                    >
                                        Esqueci a minha senha
                                    </a>
                                </div>
                                */}
                                <Button
                                    htmlType="submit"
                                    style={{
                                        marginTop: "1.0rem",
                                        width: "469px",
                                        height: "48px",
                                        backgroundColor: "#FCB814",
                                        borderRadius: "50px",
                                        fontFamily: "Source Sans Pro",
                                        fontStyle: "normal",
                                        fontWeight: "900",
                                        fontSize: "16px",
                                        lineHeight: "19px",
                                        color: "#0054B8"
                                    }}
                                >
                                    Entrar
                                </Button>
                            </Form>                            
                        </Col>
                    </Card>
                </Col>
            </Row >
        </AntdLayout >
    );
};