import {
    Form,
    Input,
  } from "@pankod/refine-antd";
  import { MyFormProps } from ".";
  
  export const ContentsForm: React.FC<MyFormProps> = ({formProps}) => {

    const formStyle = {
      textArea: {
        width: "100%",
        height: "14rem"
      }
    }

    return (
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Pagina"
          name="view"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          label="Chave"
          name="key"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Valor"
          name="value"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <textarea style={formStyle.textArea}/>
        </Form.Item>
      </Form>
    );
  
  }