import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

import { ICacheRedis } from "interfaces";
import { selectApiName, useCacheRedisStore } from "./store";

const { Title, Text } = Typography;

export const RedisShow: React.FC<IResourceComponentsProps> = () => {
  const apiName = useCacheRedisStore(selectApiName);
  const { queryResult } = useShow<ICacheRedis>({
    metaData: {
      query: {
        api: apiName,
      }
    }
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Chave</Title>
      <Text>{record?.key}</Text>

      <Title level={5}>Valor</Title>
      <pre>{JSON.stringify(record?.value, null, 2)}</pre>

      <Title level={5}>Tempo de expiração</Title>
      <Text>{record?.ttl}</Text>
    </Show>
  );
};
