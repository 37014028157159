import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  useForm,
} from "@pankod/refine-antd";
import { IHolidays } from "interfaces";
import { HolidaysForm } from "components/forms";

export const HolidaysEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IHolidays>();
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <HolidaysForm
        formProps={formProps}
        queryResult={queryResult}
      />
    </Edit>
  );
};
